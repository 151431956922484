import DateHelper from '../../../Core/helper/DateHelper.js';
import TimeSpan from '../../model/TimeSpan.js';

/**
 * @module Scheduler/feature/mixin/NonWorkingTimeMixin
 */

/**
 * Mixin with functionality shared between {@link Scheduler/feature/NonWorkingTime} and
 * {@link Scheduler/feature/EventNonWorkingTime}.
 * @mixin
 */
export default Target => class NonWorkingTimeMixin extends Target {
    static $name = 'NonWorkingTimeMixin';

    static configurable = {
        /**
         * The maximum time axis unit to display non-working ranges for ('hour' or 'day' etc).
         * When zooming to a view with a larger unit, no non-working time elements will be rendered.
         *
         * **Note:** Be careful with setting this config to big units like 'year'. When doing this,
         * make sure the timeline {@link Scheduler/view/TimelineBase#config-startDate start} and
         * {@link Scheduler/view/TimelineBase#config-endDate end} dates are set tightly.
         * When using a long range (for example many years) with non-working time elements rendered per hour,
         * you will end up with millions of elements, impacting performance.
         * When zooming, use the {@link Scheduler/view/mixin/TimelineZoomable#config-zoomKeepsOriginalTimespan} config.
         * @config {'millisecond'|'second'|'minute'|'hour'|'day'|'week'|'month'|'quarter'|'year'}
         * @default
         */
        maxTimeAxisUnit : 'week'
    };

    getNonWorkingTimeRanges(calendar, startDate, endDate) {
        // make sure we will iterate the whole interval even if it is bigger than default (5 years)
        const maxRange = endDate.getTime() - startDate.getTime() + 24 * 60 * 60 * 1000;

        if (!calendar.getNonWorkingTimeRanges) {
            const result = [];

            // <remove-on-release>
            // TODO: Ask arcady if there is a built-in way for this
            // </remove-on-release>

            calendar.forEachAvailabilityInterval(
                { startDate, endDate, isForward : true, maxRange },
                (intervalStartDate, intervalEndDate, calendarCacheInterval) => {
                    for (const [entry, cache] of calendarCacheInterval.intervalGroups) {
                        if (!cache.getIsWorking()) {
                            result.push({
                                name      : entry.name,
                                iconCls   : entry.iconCls,
                                cls       : entry.cls,
                                startDate : intervalStartDate,
                                endDate   : intervalEndDate
                            });
                        }
                    }
                }
            );

            return result;
        }

        return calendar.getNonWorkingTimeRanges(startDate, endDate, maxRange);
    }

    getCalendarTimeRanges(calendar, ignoreName = false) {
        const
            me                      = this,
            { timeAxis, fillTicks } = me.client,
            { unit, increment }     = timeAxis,
            shouldPaint             = !me.maxTimeAxisUnit || DateHelper.compareUnits(unit, me.maxTimeAxisUnit) <= 0;

        if (calendar && shouldPaint && timeAxis.count) {
            const
                allRanges     = me.getNonWorkingTimeRanges(calendar, timeAxis.startDate, timeAxis.endDate),
                timeSpans     = allRanges.map(interval => new TimeSpan({
                    name      : interval.name,
                    cls       : `b-nonworkingtime ${interval.cls || ''}`,
                    startDate : interval.startDate,
                    endDate   : interval.endDate
                })),
                mergedSpans = [];

            let prevRange = null;

            // intervals returned by the calendar are not merged, let's combine them to yield fewer elements
            for (const range of timeSpans) {
                if (prevRange && range.startDate <= prevRange.endDate && (ignoreName || range.name === prevRange.name) && range.duration > 0) {
                    prevRange.endDate = range.endDate;
                }
                else {
                    mergedSpans.push(range);
                    range.setData('id', `nonworking-${mergedSpans.length}`);
                    prevRange = range;
                }
            }

            // When filling ticks, non-working-time ranges are cropped to full ticks too
            if (fillTicks) {
                mergedSpans.forEach(span => {
                    span.setStartEndDate(
                        DateHelper.ceil(span.startDate, { magnitude : increment, unit }),
                        DateHelper.floor(span.endDate, { magnitude : increment, unit })
                    );
                });
            }

            return mergedSpans;
        }
        else {
            return [];
        }
    }

    //region Basic scheduler calendar

    setupDefaultCalendar() {
        const { client, project } = this;

        if (
            // Might have been set up by NonWorkingTime / EventNonWorkingTime already
            !this.autoGeneratedWeekends &&
            // For basic scheduler...
            !client.isSchedulerPro &&
            !client.isGantt &&
            // ...that uses the default calendar...
            project.effectiveCalendar === project.defaultCalendar &&
            // ...and has no defined intervals
            !project.defaultCalendar.intervalStore.count
        ) {
            this.autoGeneratedWeekends = true;
            this.updateDefaultCalendar();
        }
    }

    updateDefaultCalendar() {
        if (this.autoGeneratedWeekends) {
            const
                calendar     = this.client.project.effectiveCalendar,
                intervals    = this.defaultNonWorkingIntervals,
                hasIntervals = Boolean(intervals.length);

            // The default "weekends" calendar should not be time zone converted
            calendar.ignoreTimeZone = true;

            calendar.clearIntervals(hasIntervals);

            // Update weekends as non-working time
            if (hasIntervals) {
                calendar.addIntervals(intervals);
            }
        }
    }

    updateLocalization() {
        super.updateLocalization?.();

        this.autoGeneratedWeekends && this.updateDefaultCalendar();
    }

    get defaultNonWorkingIntervals() {
        const dayNames  = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        return DateHelper.nonWorkingDaysAsArray.map(dayIndex => ({
            recurrentStartDate : `on ${dayNames[dayIndex]} at 0:00`,
            recurrentEndDate   : `on ${dayNames[(dayIndex + 1) % 7]} at 0:00`,
            isWorking          : false
        }));
    }

    //endregion
};
